import {z} from 'zod';

import {FiscalOption, FiscalType} from '@prisma/client';
import {MODE_RECONSTIT_INVEST} from '~/legacy/utils/constantes';
import {getTargetSIPCase} from '~/routes/app+/sip+/_index/targetsAndSIPUtils';
import {formBooleanSchema, formNumberSchema} from '~/server/schema';

export const fiscalTypeSchema = z.enum([
  'REFORME_LMNP_RG',
  'LMNP',
  'DEFICIT_FONCIER',
  'PINEL',
  'PINEL_PLUS',
  'PINEL_DEGRADE',
  'PINEL_2024',
  'REGIME_GENERAL',
  'LMNP_MS',
  'SCELLIER',
  'MALRAUX',
  'PINEL_OM',
  'PINEL_OM_DEGRADE',
  'PINEL_OM_2024',
  'MH',
  'DEMEMBREMENT',
  'ROBIEN',
  'PINEL_DENORMANDIE',
  'SCPI',
]);

export const fiscalTypes = fiscalTypeSchema.options;

export const getFiscalTypeFromDbJson = (json: any) => {
  const validatedFiscalTypes = z.array(fiscalTypeSchema).safeParse(json);
  const fiscalTypesValue = !!json
    ? validatedFiscalTypes.success
      ? validatedFiscalTypes.data
      : fiscalTypes
    : fiscalTypes;

  return fiscalTypesValue;
};

export const fiscalTranslationFr = {
  REFORME_LMNP_RG: 'Réforme LMNP RG',
  LMNP: 'LMNP',
  LMNP_MS: 'LMNP Ancien',
  PINEL_2024: 'Pinel 2024',
  PINEL_PLUS: 'Pinel +',
  PINEL_OM_DEGRADE: 'Pinel outre-mer 2023',
  PINEL_OM_2024: 'Pinel outre-mer 2024',
  PINEL_DENORMANDIE: 'Pinel Denormandie',
  MALRAUX: 'Malraux',
  MH: 'Monument historique',
  DEFICIT_FONCIER: 'Déficit foncier',
  REGIME_GENERAL: 'Régime général',
  DEMEMBREMENT: 'Démembrement',
  SCPI: 'SCPI',
  PINEL_DEGRADE: 'Pinel 2023',
  PINEL: 'Pinel',
  PINEL_OM: 'Pinel outre-mer',
  SCELLIER: 'Scellier',
  ROBIEN: 'Robien',
} satisfies Record<FiscalType, string>;

export const fiscalTranslationShortFr = {
  REFORME_LMNP_RG: 'Réf LMNP RG',
  LMNP: 'LMNP',
  LMNP_MS: 'LMNP Ancien',
  PINEL_2024: 'Pinel 2024',
  PINEL_PLUS: 'Pinel +',
  PINEL_OM_DEGRADE: 'Pinel OM 2023',
  PINEL_OM_2024: 'Pinel OM 2024',
  PINEL_DENORMANDIE: 'Pinel Den.',
  MALRAUX: 'Malraux',
  MH: 'MH',
  DEFICIT_FONCIER: 'Déf. fonc.',
  REGIME_GENERAL: 'Régime gén.',
  DEMEMBREMENT: 'Démembrement',
  SCPI: 'SCPI',
  PINEL_DEGRADE: 'Pinel 2023',
  PINEL: 'Pinel',
  PINEL_OM: 'Pinel OM',
  SCELLIER: 'Scellier',
  ROBIEN: 'Robien',
} satisfies Record<FiscalType, string>;

export const pinelZones = ['A', 'Abis', 'B1', 'B2_C'] as const;
export type PinelZone = (typeof pinelZones)[number];

export const pinelZoneTranslationFr = {
  A: 'A bis',
  Abis: 'A',
  B1: 'B1',
  B2_C: 'B2 ou C',
} satisfies Record<PinelZone, string>;

const fiscalOptions = {
  REFORME_LMNP_RG: ['REAL', 'MICRO'],
  LMNP: ['AMORTISSABLE', 'MICRO'],
  LMNP_MS: ['AMORTISSABLE', 'MICRO'],
  PINEL_PLUS: [],
  PINEL_DEGRADE: [],
  PINEL_2024: [],
  PINEL_OM: [],
  PINEL_OM_DEGRADE: [],
  PINEL_OM_2024: [],
  PINEL_DENORMANDIE: [],
  MALRAUX: [],
  MH: [],
  DEFICIT_FONCIER: [],
  REGIME_GENERAL: ['REAL', 'MICRO', 'SCI_IS'],
  DEMEMBREMENT: [],
  SCPI: [],
} as const;

// ordre de la liste ici
const fiscalOptionsWithReconstitution = {
  REFORME_LMNP_RG: ['REAL', 'MICRO'],
  LMNP: ['AMORTISSABLE', 'CENSI_BOUVARD', 'MICRO'],
  LMNP_MS: ['AMORTISSABLE', 'MICRO'],
  PINEL: [],
  PINEL_PLUS: [],
  PINEL_DEGRADE: [],
  PINEL_2024: [],
  PINEL_OM: [],
  PINEL_OM_DEGRADE: [],
  PINEL_OM_2024: [],
  PINEL_DENORMANDIE: [],
  MALRAUX: [],
  MH: [],
  DEFICIT_FONCIER: [],
  REGIME_GENERAL: ['REAL', 'MICRO', 'SCI_IS'],
  DEMEMBREMENT: [],
  SCPI: [],
  ROBIEN: ['ROBIEN_RECENTRE', 'ROBIEN_CLASSIQUE'],
  SCELLIER: [],
} as const;

export type FiscalZone<Type extends FiscalType = FiscalType> = (typeof fiscalZones)[Type][number];

const fiscalZones = {
  REFORME_LMNP_RG: [],
  LMNP: [],
  LMNP_MS: [],
  DEFICIT_FONCIER: [],
  PINEL: pinelZones,
  PINEL_PLUS: pinelZones,
  PINEL_DEGRADE: pinelZones,
  PINEL_2024: pinelZones,
  PINEL_DENORMANDIE: pinelZones,
  PINEL_OM: pinelZones,
  PINEL_OM_DEGRADE: pinelZones,
  PINEL_OM_2024: pinelZones,
  REGIME_GENERAL: [],
  MALRAUX: [],
  DEMEMBREMENT: [],
  ROBIEN: [],
  MH: [],
  SCELLIER: [],
  SCPI: [],
} as const satisfies Record<FiscalType, readonly string[]>;

export const optionTranslationFr: Record<string, string> = {
  AMORTISSABLE: 'Amortissable',
  CENSI_BOUVARD: 'Censi-Bouvard',
  MICRO: 'Micro',
  DEFICIT_FONCIER: 'Déficit foncier',
  REAL: 'Réel',
  SCI_IS: 'SCI IS',
  ROBIEN_RECENTRE: 'Recentré',
  ROBIEN_CLASSIQUE: 'Classique',
};

const getFiscalOptions = (isReconstitution: boolean) => {
  if (isReconstitution) {
    return fiscalOptionsWithReconstitution;
  }
  return fiscalOptions;
};

const zoneTranslationFr: Record<string, string> = {
  A: 'A',
  Abis: 'A bis',
  B1: 'B1',
  B2_C: 'B2 ou C',
};

const fiscalTaxReductionsCB = [0.11, 0.13, 0.18, 0.2, 0.25] as const;
export type FiscalTaxReductionCB = (typeof fiscalTaxReductionsCB)[number];
const taxReductionRateCBFr: Record<FiscalTaxReductionCB, string> = {
  0.11: '11%',
  0.13: '13%',
  0.18: '18%',
  0.2: '20%',
  0.25: '25%',
};
const fiscalTaxReductionsScellier = [0.25, 0.22, 0.13] as const;
export type FiscalTaxReductionScellier = (typeof fiscalTaxReductionsScellier)[number];
const taxReductionRateScellierFr: Record<FiscalTaxReductionScellier, string> = {
  0.25: '25%',
  0.22: '22%',
  0.13: '13%',
};
const fiscalTaxReductionsMalraux = [0.22, 0.3] as const;
export type FiscalTaxReductionMalraux = (typeof fiscalTaxReductionsMalraux)[number];
const taxReductionRateMalrauxFr: Record<FiscalTaxReductionMalraux, string> = {
  0.22: '22%',
  0.3: '30%',
};
const getLabelFromType = (fiscalType: FiscalType, shortVersion = false) => {
  return shortVersion ? fiscalTranslationShortFr[fiscalType] : fiscalTranslationFr[fiscalType];
};

const getOptionsFromType = (fiscalType: FiscalType, isReconstitution: boolean) => {
  // AJN-482 -> tous tout le temps
  const options = getFiscalOptions(true) as Record<FiscalType, readonly string[]>;
  if (!options[fiscalType]) {
    return [];
  }
  return options[fiscalType] as FiscalOption[];
};

const getZonesFromType = (fiscalType: FiscalType) => {
  return fiscalZones[fiscalType];
};

const getLabelFromOption = (option: string) => {
  return optionTranslationFr[option];
};

const getLabelFromZone = (zone: string) => {
  return zoneTranslationFr[zone];
};

const getLabelFromTaxReductionCB = (taxReductionRate: FiscalTaxReductionCB) => taxReductionRateCBFr[taxReductionRate];

const getLabelFromTaxReductionScellier = (taxReductionRate: FiscalTaxReductionScellier) =>
  taxReductionRateScellierFr[taxReductionRate];

const getLabelFromTaxReductionMalraux = (taxReductionRate: FiscalTaxReductionMalraux) =>
  taxReductionRateMalrauxFr[taxReductionRate];

const getFiscalOptionsOptions = (fiscalType: FiscalType, isReconstitution: boolean) => {
  return getOptionsFromType(fiscalType, isReconstitution).map(option => ({
    value: option,
    label: getLabelFromOption(option),
  }));
};

const getFiscalZoneOptions = (fiscalType: FiscalType) => {
  return getZonesFromType(fiscalType).map(zone => ({
    value: zone,
    label: getLabelFromZone(zone),
  }));
};

const getFiscalOperatorOptions = () => {
  return [
    {
      value: true,
      label: 'Avec',
    },
    {
      value: false,
      label: 'Sans',
    },
  ];
};

export type ScenarioMicroParam = 'MICRO_ABATEMENT_30' | 'MICRO_ABATEMENT_40' | 'MICRO_ABATEMENT_50';
export type ScenarioRealParam = 'REAL_DEPRECIATION_0' | 'REAL_DEPRECIATION_2';
export type ScenarioParam = ScenarioMicroParam | ScenarioRealParam;

const getScenarioParam = (fiscalConfig: FiscalConfig): ScenarioParam | undefined => {
  if (fiscalConfig.type === 'REFORME_LMNP_RG') {
    if (fiscalConfig.option === 'MICRO') {
      return fiscalConfig.abatementRate === 0.3
        ? 'MICRO_ABATEMENT_30'
        : fiscalConfig.abatementRate === 0.4
          ? 'MICRO_ABATEMENT_40'
          : 'MICRO_ABATEMENT_50';
    }
    return fiscalConfig.depreciationRate === 0 ? 'REAL_DEPRECIATION_0' : 'REAL_DEPRECIATION_2';
  }
  return undefined;
};

const getReformeLmnpRgScenariosOptions = (fiscalOption: FiscalOption) => {
  if (fiscalOption === 'MICRO') {
    return [
      {
        value: 'MICRO_ABATEMENT_30',
        label: 'Abattement 30% - Scénarios 2 et 2 bis',
      },
      {
        value: 'MICRO_ABATEMENT_40',
        label: 'Abattement 40% - Scénarios 1 et 3',
      },
      {
        value: 'MICRO_ABATEMENT_50',
        label: 'Abattement 50% - Scénario 1 bis',
      },
    ];
  }
  return [
    {
      value: 'REAL_DEPRECIATION_0',
      label: 'Sans amortissement - Scénarios 1, 1 bis, 2, 2 bis',
    },
    {
      value: 'REAL_DEPRECIATION_2',
      label: 'Amortissement 2% - Scénario 3',
    },
  ];
};

const getFiscalTaxReductionOptions = (fiscalType: FiscalType, fiscalOption?: FiscalOption) => {
  if (fiscalType === 'LMNP' && fiscalOption === 'CENSI_BOUVARD') {
    return fiscalTaxReductionsCB.map(taxReductionRate => ({
      value: taxReductionRate.toString(),
      label: getLabelFromTaxReductionCB(taxReductionRate),
    }));
  }
  if (fiscalType === 'SCELLIER') {
    return fiscalTaxReductionsScellier.map(taxReductionRate => ({
      value: taxReductionRate.toString(),
      label: getLabelFromTaxReductionScellier(taxReductionRate),
    }));
  }
  if (fiscalType === 'MALRAUX') {
    return fiscalTaxReductionsMalraux.map(taxReductionRate => ({
      value: taxReductionRate.toString(),
      label: getLabelFromTaxReductionMalraux(taxReductionRate),
    }));
  }
  return [];
};

const fiscalBooleanFactory =
  (...types: FiscalType[]) =>
  (fiscalType: FiscalType) => {
    return types.some(type => type === fiscalType);
  };

export const pinelTypeList = [
  'PINEL',
  'PINEL_PLUS',
  'PINEL_DEGRADE',
  'PINEL_2024',
  'PINEL_DENORMANDIE',
  'PINEL_OM',
  'PINEL_OM_DEGRADE',
  'PINEL_OM_2024',
] as const;

const isPinel = (fiscalConfig: FiscalConfig) => fiscalBooleanFactory(...pinelTypeList)(fiscalConfig.type);
const isMH = (fiscalConfig: FiscalConfig) => fiscalConfig.type === 'MH';
const isMalraux = (fiscalConfig: FiscalConfig) => fiscalConfig.type === 'MALRAUX';
const isScellier = (fiscalConfig: FiscalConfig) => fiscalConfig.type === 'SCELLIER';
const isDf = (fiscalConfig: FiscalConfig) => fiscalConfig.type === 'DEFICIT_FONCIER';

const isFurnishable = (fiscalConfig: FiscalConfig) =>
  fiscalBooleanFactory(
    'PINEL',
    'PINEL_2024',
    'PINEL_DEGRADE',
    'PINEL_DENORMANDIE',
    'PINEL_OM',
    'PINEL_OM_2024',
    'PINEL_OM_DEGRADE',
    'PINEL_PLUS',
    'SCELLIER',
    'DEFICIT_FONCIER',
    'REGIME_GENERAL',
    'ROBIEN',
    'MALRAUX',
    'MH',
  )(fiscalConfig.type);
const isLmnpAny = (fiscalConfig: FiscalConfig) => fiscalBooleanFactory('LMNP', 'LMNP_MS')(fiscalConfig.type);
const isLmnpAnyMicro = (fiscalConfig: FiscalConfig) => isLmnpAny(fiscalConfig) && fiscalConfig.option === 'MICRO';
const isLmnpAnyNotMicro = (fiscalConfig: FiscalConfig) => isLmnpAny(fiscalConfig) && !(fiscalConfig.option === 'MICRO');

const isVefa = (fiscalConfig: FiscalConfig) =>
  fiscalBooleanFactory(
    'PINEL',
    'PINEL_PLUS',
    'PINEL_DEGRADE',
    'PINEL_2024',
    'PINEL_DENORMANDIE',
    'PINEL_OM',
    'PINEL_OM_DEGRADE',
    'PINEL_OM_2024',
    'DEMEMBREMENT',
    'LMNP',
    'ROBIEN',
    'SCELLIER',
  )(fiscalConfig.type);

const getFiscalTypeOptions = (isReconstitution: boolean) => {
  // AJN-482
  return Object.keys(getFiscalOptions(true)).map(fiscalType => ({
    value: fiscalType as FiscalType,
    label: getLabelFromType(fiscalType as FiscalType),
  }));
};

export const performanceTypeSchema = z.enum(['FINANCIAL_PERFORMANCE', 'RENTABILITY_RATE', 'TRI']);

const parseFurnishingYearIndex = formNumberSchema
  .optional()
  .nullable()
  .transform(v => (v === null || v === -1 ? undefined : v));

export const fiscalConfigSchema = z.intersection(
  z
    .union([
      z.intersection(
        z.object({
          type: z.literal('REFORME_LMNP_RG'),
          zone: z.any().transform(() => undefined),
          operator: z.any().transform(() => undefined),
          furnishingYearIndex: z.any().transform(() => undefined),
          hasVAT: z.any().transform(() => undefined),
        }),
        z.union([
          z.object({
            option: z.enum(['MICRO']).default('MICRO'),
            taxReductionRate: z.any().transform(() => undefined),
            depreciationRate: z.any().transform(() => undefined),
            abatementRate: formNumberSchema.default(0.4),
          }),
          z.object({
            option: z.enum(['REAL']),
            taxReductionRate: formNumberSchema.default(0.11),
            depreciationRate: formNumberSchema.default(0),
            abatementRate: z.any().transform(() => undefined),
          }),
        ]),
      ),
      z.intersection(
        z.object({
          type: z.literal('LMNP'),
          zone: z.any().transform(() => undefined),
          operator: formBooleanSchema,
          furnishingYearIndex: z.any().transform(() => undefined),
          hasVAT: z.any().transform(() => undefined),
          depreciationRate: z.any().transform(() => undefined),
          abatementRate: z.any().transform(() => undefined),
        }),
        z.union([
          z.object({
            option: z.enum(['AMORTISSABLE', 'MICRO']).default('AMORTISSABLE'),
            taxReductionRate: z.any().transform(() => undefined),
          }),
          z.object({
            option: z.enum(['CENSI_BOUVARD']),
            taxReductionRate: formNumberSchema.default(0.11),
          }),
        ]),
      ),
      z.object({
        type: z.literal('LMNP_MS'),
        option: z.enum(['AMORTISSABLE', 'MICRO']).default('AMORTISSABLE'),
        zone: z.any().transform(() => undefined),
        operator: formBooleanSchema,
        taxReductionRate: z.any().transform(() => undefined),
        furnishingYearIndex: z.any().transform(() => undefined),
        hasVAT: z.any().transform(() => undefined),
        depreciationRate: z.any().transform(() => undefined),
        abatementRate: z.any().transform(() => undefined),
      }),
      z.object({
        type: z.literal('DEFICIT_FONCIER'),
        option: z.any().transform(() => undefined),
        zone: z.any().transform(() => undefined),
        operator: z.any().transform(() => undefined),
        taxReductionRate: z.any().transform(() => undefined),
        hasVAT: formBooleanSchema.default(false),
        furnishingYearIndex: parseFurnishingYearIndex,
        depreciationRate: z.any().transform(() => undefined),
        abatementRate: z.any().transform(() => undefined),
      }),
      z.object({
        type: z.literal('PINEL_DENORMANDIE'),
        operator: z.any().transform(() => undefined),
        taxReductionRate: z.any().transform(() => undefined),
        option: z.enum(['VIR', 'ASL']).default('VIR'),
        zone: z.enum(['A', 'Abis', 'B1', 'B2_C']).default('A'),
        hasVAT: z.any().transform(() => undefined),
        furnishingYearIndex: parseFurnishingYearIndex,
        depreciationRate: z.any().transform(() => undefined),
        abatementRate: z.any().transform(() => undefined),
      }),
      z.object({
        type: z.enum([
          'PINEL',
          'PINEL_PLUS',
          'PINEL_DEGRADE',
          'PINEL_2024',
          'PINEL_OM',
          'PINEL_OM_DEGRADE',
          'PINEL_OM_2024',
        ]),
        operator: z.any().transform(() => undefined),
        taxReductionRate: z.any().transform(() => undefined),
        option: z.any().transform(() => undefined),
        zone: z.enum(['A', 'Abis', 'B1', 'B2_C']).default('A'),
        hasVAT: z.any().transform(() => undefined),
        furnishingYearIndex: parseFurnishingYearIndex,
        depreciationRate: z.any().transform(() => undefined),
        abatementRate: z.any().transform(() => undefined),
      }),

      z.intersection(
        z.object({
          type: z.literal('REGIME_GENERAL'),
          operator: z.any().transform(() => undefined),
          taxReductionRate: z.any().transform(() => undefined),
          zone: z.any().transform(() => undefined),
          furnishingYearIndex: parseFurnishingYearIndex,
          depreciationRate: z.any().transform(() => undefined),
          abatementRate: z.any().transform(() => undefined),
        }),
        z.union([
          z.object({
            option: z.literal('SCI_IS'),
            hasVAT: formBooleanSchema.default(false),
          }),
          z.object({
            option: z.enum(['REAL', 'MICRO']).default('REAL'),
            hasVAT: z.any().transform(() => undefined),
          }),
        ]),
      ),
      z.object({
        type: z.literal('MALRAUX'),
        option: z.enum(['VIR', 'ASL']).default('VIR'),
        operator: z.any().transform(() => undefined),
        zone: z.any().transform(() => undefined),
        taxReductionRate: formNumberSchema.default(0.3),
        furnishingYearIndex: parseFurnishingYearIndex,
        hasVAT: z.any().transform(() => undefined),
        depreciationRate: z.any().transform(() => undefined),
        abatementRate: z.any().transform(() => undefined),
      }),
      z.object({
        type: z.literal('MH'),
        option: z.any().transform(() => undefined),
        operator: z.any().transform(() => undefined),
        zone: z.any().transform(() => undefined),
        taxReductionRate: z.any().transform(() => undefined),
        furnishingYearIndex: parseFurnishingYearIndex,
        hasVAT: z.any().transform(() => undefined),
        depreciationRate: z.any().transform(() => undefined),
        abatementRate: z.any().transform(() => undefined),
      }),
      z.object({
        type: z.literal('DEMEMBREMENT'),
        option: z.any().transform(() => undefined),
        operator: z.any().transform(() => undefined),
        taxReductionRate: z.any().transform(() => undefined),
        zone: z.any().transform(() => undefined),
        furnishingYearIndex: z.any().transform(() => undefined),
        hasVAT: z.any().transform(() => undefined),
        depreciationRate: z.any().transform(() => undefined),
        abatementRate: z.any().transform(() => undefined),
      }),
      z.object({
        type: z.literal('ROBIEN'),
        option: z.enum(['ROBIEN_RECENTRE', 'ROBIEN_CLASSIQUE']).default('ROBIEN_RECENTRE'),
        taxReductionRate: z.any().transform(() => undefined),
        operator: z.any().transform(() => undefined),
        zone: z.any().transform(() => undefined),
        furnishingYearIndex: parseFurnishingYearIndex,
        hasVAT: z.any().transform(() => undefined),
        depreciationRate: z.any().transform(() => undefined),
        abatementRate: z.any().transform(() => undefined),
      }),
      z.object({
        type: z.literal('SCELLIER'),
        operator: z.any().transform(() => undefined),
        option: z.any().transform(() => undefined),
        taxReductionRate: formNumberSchema.default(0.13),
        zone: z.any().transform(() => undefined),
        furnishingYearIndex: parseFurnishingYearIndex,
        hasVAT: z.any().transform(() => undefined),
        depreciationRate: z.any().transform(() => undefined),
        abatementRate: z.any().transform(() => undefined),
      }),
      z.object({
        type: z.literal('SCPI'),
        option: z.any().transform(() => undefined),
        operator: z.any().transform(() => undefined),
        taxReductionRate: z.any().transform(() => undefined),
        zone: z.any().transform(() => undefined),
        furnishingYearIndex: z.any().transform(() => undefined),
        hasVAT: z.any().transform(() => undefined),
        depreciationRate: z.any().transform(() => undefined),
        abatementRate: z.any().transform(() => undefined),
      }),
    ])
    .default({type: 'LMNP', option: 'AMORTISSABLE', operator: true}),
  z.object({
    isReconstitution: formBooleanSchema.default(false),
    isSuivi: formBooleanSchema.default(false),
    isCredit: formBooleanSchema.default(false),
    selectedPerformanceType: performanceTypeSchema.default('FINANCIAL_PERFORMANCE'),
  }),
);

export type FiscalConfig = z.infer<typeof fiscalConfigSchema>;
export type PerformanceType = z.infer<typeof performanceTypeSchema>;

export const perfTypeTranslationFr: Record<PerformanceType, string> = {
  FINANCIAL_PERFORMANCE: 'Performance financière',
  RENTABILITY_RATE: 'Taux de rentabilité',
  TRI: 'Taux de rendement interne',
};
export const perfTypeSubtitleTranslationFr: Record<PerformanceType, string | undefined> = {
  FINANCIAL_PERFORMANCE: 'annuelle nette moyenne',
  RENTABILITY_RATE: 'annuelle nette moyenne',
  TRI: undefined,
};

export const perfTypeTranslationFrFirstLowerCase: Record<PerformanceType, string> = {
  FINANCIAL_PERFORMANCE:
    perfTypeTranslationFr.FINANCIAL_PERFORMANCE.charAt(0).toLowerCase() +
    perfTypeTranslationFr.FINANCIAL_PERFORMANCE.slice(1),
  RENTABILITY_RATE:
    perfTypeTranslationFr.RENTABILITY_RATE.charAt(0).toLowerCase() + perfTypeTranslationFr.RENTABILITY_RATE.slice(1),
  TRI: perfTypeTranslationFr.TRI.charAt(0).toLowerCase() + perfTypeTranslationFr.TRI.slice(1),
};

interface MinimalInvestmentForIsSuivi {
  initialSimulationId?: number | null;
}
export const isSuivi = (investment?: MinimalInvestmentForIsSuivi | null) => {
  return (
    investment?.initialSimulationId !== -1 &&
    investment?.initialSimulationId !== null &&
    investment?.initialSimulationId !== undefined
  );
};

interface MinimalInvestment {
  reconstit_invest?: number | null;
  initialSimulationId?: number | null;
  perf_invest_selectionnee?: string | null;
}
interface MinimalProperty {
  fiscalType: FiscalType;
  fiscalOption?: FiscalOption | null;
  taxReductionRate?: number | null;
  depreciationRate?: number | null;
  abatementRate?: number | null;
  zone_pinel?: string | null;
  TVA_SCI?: number | null;
  operator?: boolean | null;
  furnishingYearIndex?: number | null;
}
interface MinimalPropertyFunding {
  type_financement?: number | null;
}
export const getFiscalConfig = (
  property: MinimalProperty & {
    investment?: MinimalInvestment | null;
    propertyFunding?: MinimalPropertyFunding | null;
  },
) => {
  const type = property.fiscalType || 'LMNP';
  return fiscalConfigSchema.parse({
    type,
    option: property.fiscalOption,
    zone: (property.zone_pinel as FiscalZone) || undefined,
    hasVAT: property.TVA_SCI === 1,
    furnishingYearIndex: property.furnishingYearIndex,
    operator: !!property.operator,
    taxReductionRate: property.taxReductionRate,
    abatementRate: property.abatementRate,
    depreciationRate: property.depreciationRate,
    isReconstitution: property.investment?.reconstit_invest === MODE_RECONSTIT_INVEST,
    isSuivi: isSuivi(property.investment),
    isCredit: property.propertyFunding?.type_financement === 2,
    selectedPerformanceType: getPerformanceTypeFromDb(property.investment?.perf_invest_selectionnee),
  });
};

export const getFiscalConfigFromInvestment = (
  investment: MinimalInvestment & {
    properties: (MinimalProperty & {
      propertyFunding?: MinimalPropertyFunding | null;
    })[];
  },
): FiscalConfig | undefined => {
  const fiscalConfigs = investment.properties.map(p => getFiscalConfig({...p, investment}));
  if (fiscalConfigs.length === 0) {
    return undefined;
  }
  return fiscalConfigs[0];
};

export const getPerformanceTypeFromDb = (perf?: string | null) => {
  if (perf === 'TRI') {
    return 'TRI';
  }
  if (perf === 'tauxRentabilite') {
    return 'RENTABILITY_RATE';
  }
  return 'FINANCIAL_PERFORMANCE';
};

const isFurnishedFurnishable = (fiscalConfig: FiscalConfig) =>
  isFurnishable(fiscalConfig) && fiscalConfig.furnishingYearIndex !== undefined;
const isUnfurnishedFurnishable = (fiscalConfig: FiscalConfig) =>
  isFurnishable(fiscalConfig) && fiscalConfig.furnishingYearIndex === undefined;

export const isOneOf = <O extends string, Opt extends O>(option?: O, ...options: Opt[]): option is Opt =>
  options.some(o => o === option);

const isRgSCIIS = (fiscalConfig: FiscalConfig) =>
  fiscalConfig.type === 'REGIME_GENERAL' && fiscalConfig.option === 'SCI_IS';
const isRgSCIISTVA = (fiscalConfig: FiscalConfig) => isRgSCIIS(fiscalConfig) && !!fiscalConfig.hasVAT;
const isDfTVA = (fiscalConfig: FiscalConfig) => isDf(fiscalConfig) && !!fiscalConfig.hasVAT;
const isCGA = (fiscalConfig: FiscalConfig) =>
  isOneOf(fiscalConfig.type, 'LMNP', 'LMNP_MS') && !(fiscalConfig.option === 'MICRO');

const isMicro = (fiscalConfig: FiscalConfig) => fiscalConfig.option === 'MICRO';
const isAlwaysBic = (fiscalConfig: FiscalConfig) => fiscalConfig.type === 'LMNP' || fiscalConfig.type === 'LMNP_MS';
const isFoncier = (fiscalConfig: FiscalConfig, yearIndex?: number) => {
  if (yearIndex === undefined) {
    return !isAlwaysBic(fiscalConfig) && !isRgSCIIS(fiscalConfig);
  }
  return (
    !isAlwaysBic(fiscalConfig) &&
    (fiscalConfig.furnishingYearIndex === undefined || yearIndex < fiscalConfig.furnishingYearIndex)
  );
};
const isBic = (fiscalConfig: FiscalConfig, yearIndex?: number) => {
  if (yearIndex === undefined) {
    return isAlwaysBic(fiscalConfig);
  }
  return (
    isAlwaysBic(fiscalConfig) ||
    (fiscalConfig.furnishingYearIndex !== undefined && yearIndex >= fiscalConfig.furnishingYearIndex)
  );
};
const isAlwaysFoncier = (fiscalConfig: FiscalConfig) => {
  return !isAlwaysBic(fiscalConfig) && fiscalConfig.furnishingYearIndex === undefined;
};
const hasGotFurnished = (fiscalConfig: FiscalConfig) => {
  return !isAlwaysFoncier(fiscalConfig) && isFoncier(fiscalConfig);
};
const hasOperator = (fiscalConfig: FiscalConfig) => {
  return (fiscalConfig.type === 'LMNP' || fiscalConfig.type === 'LMNP_MS') && !!fiscalConfig.operator;
};

const getLabel = (fiscalConfig: FiscalConfig) => {
  const typeLabel = getLabelFromType(fiscalConfig.type);
  const optionLabel = fiscalConfig.option ? getLabelFromOption(fiscalConfig.option) : undefined;

  return `${typeLabel} ${optionLabel ? optionLabel : ''}`;
};

export const fiscalConfigService = {
  getLabel,
  getLabelFromType,
  getOptionsFromType,
  getZonesFromType,
  getLabelFromZone,
  getFiscalOptionsOptions,
  getFiscalZoneOptions,
  getFiscalOperatorOptions,
  getFiscalTaxReductionOptions,
  getReformeLmnpRgScenariosOptions,
  getScenarioParam,
  getFiscalTypeOptions,
  getFiscalConfig,
  getTargetSIPCase: ({type}: {type: FiscalType}) => getTargetSIPCase(type),
  is: {
    furnishable: isFurnishable,
    furnishedFurnishable: isFurnishedFurnishable,
    unfurnishedFurnishable: isUnfurnishedFurnishable,

    pinel: isPinel,
    MH: isMH,
    malraux: isMalraux,
    furnishedPinel: (fiscalConfig: FiscalConfig) =>
      isPinel(fiscalConfig) && fiscalConfig.furnishingYearIndex !== undefined,
    unfurnishedPinel: (fiscalConfig: FiscalConfig) =>
      isPinel(fiscalConfig) && fiscalConfig.furnishingYearIndex === undefined,

    scellier: isScellier,
    furnishedScellier: (fiscalConfig: FiscalConfig) =>
      isScellier(fiscalConfig) && fiscalConfig.furnishingYearIndex !== undefined,
    unfurnishedScellier: (fiscalConfig: FiscalConfig) =>
      isScellier(fiscalConfig) && fiscalConfig.furnishingYearIndex === undefined,

    demembrement: (fiscalConfig: FiscalConfig) => fiscalConfig.type === 'DEMEMBREMENT',
    df: isDf,
    furnishedDf: (fiscalConfig: FiscalConfig) => isDf(fiscalConfig) && fiscalConfig.furnishingYearIndex !== undefined,
    unfurnishedDf: (fiscalConfig: FiscalConfig) => isDf(fiscalConfig) && fiscalConfig.furnishingYearIndex === undefined,

    pinelMetroAny: (fiscalConfig: FiscalConfig) =>
      fiscalBooleanFactory('PINEL', 'PINEL_DEGRADE', 'PINEL_2024', 'PINEL_PLUS')(fiscalConfig.type),
    pinelOMAny: (fiscalConfig: FiscalConfig) =>
      fiscalBooleanFactory('PINEL_OM', 'PINEL_OM_DEGRADE', 'PINEL_OM_2024')(fiscalConfig.type),
    vefa: isVefa,

    lmnpAny: isLmnpAny,
    lmnpAnyMicro: isLmnpAnyMicro,
    lmnpAnyNotMicro: isLmnpAnyNotMicro,
    lmnpAnyWithOperator: (fiscalConfig: FiscalConfig) => isLmnpAny(fiscalConfig) && fiscalConfig.operator,
    lmnpAnyWithoutOperator: (fiscalConfig: FiscalConfig) => isLmnpAny(fiscalConfig) && !fiscalConfig.operator,
    censiBouvard: (fiscalConfig: FiscalConfig) =>
      fiscalBooleanFactory('LMNP')(fiscalConfig.type) && fiscalConfig.option === 'CENSI_BOUVARD',

    micro: isMicro,
    // RG
    rgReel: (fiscalConfig: FiscalConfig) =>
      fiscalBooleanFactory('REGIME_GENERAL')(fiscalConfig.type) && fiscalConfig.option === 'REAL',
    rgSCIIS: isRgSCIIS,
    rgSCIISTVA: isRgSCIISTVA,
    dfTVA: isDfTVA,

    // BIC / Foncier
    alwaysBic: isAlwaysBic,
    alwaysFoncier: isAlwaysFoncier,
    foncier: isFoncier,
    bic: isBic,
  },
  has: {
    cga: isCGA,
    accoutingFees: (fiscalConfig: FiscalConfig) => isCGA(fiscalConfig) || isRgSCIIS(fiscalConfig),
    operator: hasOperator,
  },
  gotFurnished: hasGotFurnished,
};

export const globalFiscalConfigService = (
  fiscalConfigs: FiscalConfig[],
  everyOrSome: 'some' | 'every',
  isFunction: keyof (typeof fiscalConfigService)['is'],
) => {
  return everyOrSome === 'every'
    ? fiscalConfigs.every(f => fiscalConfigService.is[isFunction](f))
    : fiscalConfigs.some(f => fiscalConfigService.is[isFunction](f));
};
