import {SCPIFrequencyType} from '@prisma/client';
import {DepreciationParams, DepreciationType} from '~/domains/property/property-type';
import {LoanType, TypeCharges} from '~/legacy/common/types/main';

// Nombre d'années de la simulation
// !!! une fois des clients créés en base de données,
// plus de possibilité de changement)
export const NB_ANNEES_SIMU = 30;

// TODO : default values in admin settings page

export const HYP_REVAL_LOYERS_DEFAUT = 1.5;
export const HYP_REVAL_CHARGES_DEFAUT = 1.5;
export const HYP_REVAL_BIENS_DEFAUT = 0;

export const defaultYearlyRentFee = 0.12;
export const defaultYearlyPropertyInsurance = 120;
export const valDefautProvFrST = 70;
export const valDefautHonComptables = 265;
export const valDefautHonComptablesLotSupplementaire = 120;
export const valDefautAdhCGAMontant = 120;

export const valDefautTVASCI = 10;
export const valDefautPeriodiciteSCPI: SCPIFrequencyType = 'QUARTERLY';
export const valDefautTauxDistributionSCPI = 0.055;
export const valDefautTauxFraisGestionSCPI = 0.12;
export const valDefautTauxIFI = 0.005;

export const defaultLoanRate = 3;
export const valFraisDossierDefaut = 930;
export const defaultAdiRate = 0.3;
export const defaultLoanType: LoanType = 'AMORTIZING'; // Amortissable
export const TAUX_IRA_PART_CRD = 0.03;
export const repartitionDefaut = '50/50';
export const defaultLoanDuration = 240;

export const TYPE_GB_CAUTION = 1;
export const TYPE_GB_HYPOTHEQUE = 2;
export const TYPE_GB_PPB = 3;

export const FORFAIT_DIAGNOSTIC_DEFAULT = 150;

export type TypeHypReval = 'loyers' | 'charges' | 'biens';

export const TAUX_TVA = 0.2;

export const TAUX_PROV_GAR_BANCAIRE = 0.013557;

// Select : Situation familiale
export const SITFAM_CELIB = '1';
export const SITFAM_MARIES = '2';
export const SITFAM_PACSES = '3';
export const SITFAM_DIVORCE = '4';
export const SITFAM_VEUF = '5';

// Select : Beneficiaire : Type de client
export const MODE_TYPE_CLIENT_PROSPECT = 1;
export const MODE_TYPE_CLIENT_CLIENT = 2;

// Select : Objectifs : Mode de financement
export const MODE_FIN_AUTOFIN = '1';
export const MODE_FIN_CREDIT = '2';
export const MODE_FIN_MELANGE = '3';
// apport partiel ou non si crédit
export const MODE_APPORT_PARTIEL = '1';
export const MODE_CREDIT_TOTAL = '2';
// reconstitution d'investissement
export const MODE_RECONSTIT_INVEST = 1;
export const MODE_NOUVEAU_PROJET = 2;

export const PART_ROBIEN_AMORT_GROS_OEUVRE_ANNEES_1_5 = 0.08;
export const PART_ROBIEN_AMORT_GROS_OEUVRE_ANNEES_6_15 = 0.025;

// Select : Dispositifs : options
export const OPTION_DIVERS = '-1';
export const OPTION_AMORTISSABLE = '1';
export const OPTION_CENSI_BOUVARD = '2';
export const OPTION_MICROBIC = '3';
export const OPTION_MICROFONCIER = '4';
export const OPTION_SCELLIER_25 = '6';
export const OPTION_SCELLIER_22 = '7';
export const OPTION_SCELLIER_13 = '8';
export const OPTION_PINEL_OM_23 = '9';
// export const OPTION_PINEL_OM_29 = '10';
// export const OPTION_PINEL_OM_32 = '11';
export const OPTION_MALRAUX_22 = '12';
export const OPTION_MALRAUX_30 = '13';
// Oui / Non (Pinel meublé)
export const OUI = '9';
export const NON = '10';
export const OPTION_REEL = '11'; // option de RG
export const OPTION_SCIIS = '12'; // option de RG
export const OPTION_ROBIEN_RECENTRE = '20'; // option de Robien
export const OPTION_ROBIEN_CLASSIQUE = '21'; // option de Robien

// coefficient abattement Micro
export const COEF_ABATT_MICROBIC = 0.5;
export const COEF_ABATT_MICROFONCIER = 0.3;

// Select : Financement de la TVA
export const FIN_TVA_EMPRUNTEE = '1';
export const FIN_TVA_PROMOTEUR = '2';
export const FIN_TVA_AVANCEE = '3';

// Select : Financement des frais pendant la phase de construction
export const FPC_EMPRUNTES = '1';
export const FPC_NON_EMPRUNTES = '0';

// Select : Type de prêt
export const PRET_AMORTISSABLE = '1';
export const PRET_IN_FINE = '2';

// Select : Zone Pinel : limite
export const PINEL_ZONE_A_BIS_VAL = 17.17;
export const PINEL_ZONE_A_VAL = 12.75;
export const PINEL_ZONE_B1_VAL = 10.28;
export const PINEL_ZONE_B2_C_VAL = 8.93;

// Select : Zone Pinel : nom
export const PINEL_ZONE_A_BIS = 'Abis';
export const PINEL_ZONE_A = 'A';
export const PINEL_ZONE_B1 = 'B1';
export const PINEL_ZONE_B2_C = 'B2_C';

export const valDefautZonePinel = 'B1';

// Taux des charges copro à la charge de l'investisseur
export const TAUX_CHARGES_COPRO_INVESTISSEUR = 0.35;

// Plus-value immobilière
export const TAUX_TRAVAUX_SANS_JUSTIF_POUR_PV_IMMO = 0.15;

// apport partiel ou non si crédit
export const MODE_HYP_REVAL_BIEN = '1';
export const MODE_PRIX_ESTIME_FIXE = '0';

// Calcul de reduction_impot_LMNP_CGA dans l'étude détaillée
export const NB_ANNEES_REDUC_IMPOTS_0 = 6;
export const NB_ANNEES_REDUC_IMPOTS = 9;
export const NB_ANNEES_REDUC_IMPOTS_2 = 12;

// Calcul de la Base amortissable
export const PLAFOND_BASE_AMORT_CB = 300000;

// Equivalent Assurance vie
export const AV_TAUX_FRAIS_VERSEMENT = 0.01;
export const AV_TAUX_FRAIS_GESTION = 0.005;

// INTERFACE
export const ANNEES_PAR_PAGE = 10;

export const TAUX_PRISE_COMPTE_LOYERS_POUR_TE = 0.7;

export const typesValeurRevente: TypeCharges[] = ['residencePrincipale', 'investissementLocatif'];

export const typesLoyer: TypeCharges[] = ['investissementLocatif'];

// TYPE_USER (MAJ septembre octobre 2022)
// 1 : Admin
// 20 à 29 : sociétés : patron, conseillers
// 50 : clients
// 60 à 69 : courtiers
// 70 à 79 : comptables
// 80 à 89 : fournisseurs
// 90 : visiteur (non implémenté)
// 100 : public (valeur par défaut our l'instant -> droits minimum)

// ADMINS
export const TYPE_USER_ADMIN = 1;

// SOCIETES
export const TYPE_USER_CHEF_SOCIETE = 29;
export const TYPE_USER_CONSEILLER = 25;

export const TAUX_ENDETTEMENT_SOUHAITE = 0.35;

export const TRAVAUX_AMORTIS_PAR_AN_DF = 10700;
export const TRAVAUX_AMORTIS_PAR_AN_DF_CLIMAT = 21400;
export const TRAVAUX_AMORTIS_PAR_AN_MH = 100000000;

export const MAX_WORKCOSTS_MALRAUX = 400000;
export const MIN_WORKCOSTYEAR1_RATE = 0.35;

export const depreciationNotRgSciIsDefault: Record<DepreciationType, DepreciationParams> = {
  igtInstallation: {duration: 15, rate: 30},
  layout: {duration: 7, rate: 25},
  structuralWork: {duration: 50, rate: 40},
  facade: {duration: 20, rate: 5},
  furniture: {duration: 7, rate: 100},
};
export const depreciationRgSciIsDefault: Record<DepreciationType, DepreciationParams> = {
  igtInstallation: {duration: 20, rate: 17},
  layout: {duration: 10, rate: 11},
  structuralWork: {duration: 30, rate: 55},
  facade: {duration: 20, rate: 17},
  furniture: {duration: 7, rate: 100},
};
